const AD_CAMPAIGN = "ad-campaign"
const BIG_IDEAS = "big-ideas"
const BLOG_TOPICS = "blog-topics"
const CAMPAIGN_THEME = "campaign-theme"
const CONTEST_IDEAS = "contest-ideas"
const CREATIVE_FREESTYLE = "creative-freestyle"
const CREATIVE_INSIGHTS = "creative-insights"
const CRISIS_RESPONSE = "crisis-response"
const EVENT_IDEAS = "event-ideas"
const GUERILLA_MARKETING = "guerilla-marketing"
const INFLUENCER_MARKETING = "influencer-marketing"
const INVENTO = "invento"
const MOBILE_MARKETING = "mobile-marketing"
const NEWSLETTER_TOPICS = "newsletter-topics"
const PODCAST_EPISODES = "podcast-episodes"
const PROBLEM_SOLVER = "problem-solver"
const PROMOTION_IDEA = "promotion-idea"
const PUBLICITY_STUNT = "publicity-stunt"
const VIDEO_TREATMENT = "video-treatment"
const VIRAL_IDEAS = "viral-ideas"
const ADVANCED_IDEA_GENERATOR = "advanced-idea-generator"
const STRATEGY_THINKING = "strategy-thinking"

export const BRAINSTORM_TYPES = {
  AD_CAMPAIGN,
  BIG_IDEAS,
  BLOG_TOPICS,
  CAMPAIGN_THEME,
  CONTEST_IDEAS,
  CREATIVE_FREESTYLE,
  CREATIVE_INSIGHTS,
  CRISIS_RESPONSE,
  EVENT_IDEAS,
  GUERILLA_MARKETING,
  INFLUENCER_MARKETING,
  INVENTO,
  MOBILE_MARKETING,
  NEWSLETTER_TOPICS,
  PODCAST_EPISODES,
  PROBLEM_SOLVER,
  PROMOTION_IDEA,
  PUBLICITY_STUNT,
  VIDEO_TREATMENT,
  VIRAL_IDEAS,
  ADVANCED_IDEA_GENERATOR,
  STRATEGY_THINKING,
}

export const BRAINSTORM_TYPE_LABELS = {
  [ADVANCED_IDEA_GENERATOR]: "Advanced Idea Generator",
  [AD_CAMPAIGN]: "Advertising Strategy",
  [ADVANCED_IDEA_GENERATOR]: "Advanced Idea Generator",
  [BIG_IDEAS]: "Big Idea (Campaign)",
  [BLOG_TOPICS]: "Blog Post Ideas",
  [CREATIVE_FREESTYLE]: "Brainstorm Unlimited",
  [CAMPAIGN_THEME]: "Campaign Ideas",
  [CONTEST_IDEAS]: "Contest Ideas",
  [CREATIVE_INSIGHTS]: "Creative Insights",
  [CRISIS_RESPONSE]: "Crisis Response",
  [EVENT_IDEAS]: "Event Ideas",
  [GUERILLA_MARKETING]: "Guerilla Marketing Tactics",
  [INFLUENCER_MARKETING]: "Influencer Marketing Strategy",
  [MOBILE_MARKETING]: "Mobile Marketing Strategy",
  [INVENTO]: "New Product Ideas",
  [NEWSLETTER_TOPICS]: "Newsletter Ideas",
  [PODCAST_EPISODES]: "Podcast Ideas",
  [PROBLEM_SOLVER]: "Problem Solver",
  [PROMOTION_IDEA]: "Promotion Ideas",
  [PUBLICITY_STUNT]: "Publicity Stunts",
  [STRATEGY_THINKING]: "Strategic Thinking",
  [VIDEO_TREATMENT]: "Video Treatment",
  [VIRAL_IDEAS]: "Viral Video Ideas",
}
export const BRAINSTORM_TYPE_PLACEHOLDERS = {
  [AD_CAMPAIGN]:
    "e.g. Give me an ad campaign idea for a new dating app called Glimpse",
  [BIG_IDEAS]: "e.g. How might we design a more engaging banking experience?",
  [CRISIS_RESPONSE]:
    "e.g. A crisis response for a snack company facing a class action lawsuit",
  [CREATIVE_FREESTYLE]:
    "e.g. Give me a content strategy for a Spring Water brand",
  [BLOG_TOPICS]:
    "e.g. Give me a topic on the impact of AI on professional writers.",
  [PUBLICITY_STUNT]:
    "e.g. Give me a publicity stunt for an AI software company",
  [PROBLEM_SOLVER]: "e.g. Give me a solution for doing more work in less time",
  [EVENT_IDEAS]: "e.g. Give me a launch event idea for an AI software company",
  [INVENTO]:
    "Invent a new product or service using an analogy. E.g. A fish in a bowl.",
  [PODCAST_EPISODES]:
    "e.g. Give me an episode on How to Create Great Content with A.I",
  [CAMPAIGN_THEME]: "e.g. Give me campaign idea promoting AI writing software",
  [CONTEST_IDEAS]:
    "e.g. Give me a social media contest idea for promoting an AI software company",
  [VIRAL_IDEAS]:
    "e.g. Give me a Tik Tok video idea for promoting an AI software company",
  [PROMOTION_IDEA]:
    "Thinking of launching a summer product line. Give me a promotional concept.",
  [VIDEO_TREATMENT]:
    "e.g. Give me a treatment for an ad about AI writing software ",
  [CREATIVE_INSIGHTS]: "Enter your creative brief",
  [NEWSLETTER_TOPICS]:
    "Enter a subject - e.g. Impact of AI of professional writers in 2023",
  [MOBILE_MARKETING]:
    "e.g. Give me a mobile marketing idea for a new productivity app called 'Focus'",
  [INFLUENCER_MARKETING]:
    "e.g. Create an influencer marketing campaign for a new dating app called Glimpse",
  [GUERILLA_MARKETING]:
    "e.g. Give me an idea for a new dating app called Glimpse",
  [ADVANCED_IDEA_GENERATOR]:
    "Give me a go to market strategy for a new dating app called Glimpse",
  [STRATEGY_THINKING]:
    "Glimpse is a new dating app that uses AI to match people based on their interests. How might we design a go to market strategy for Glimpse?",
}
