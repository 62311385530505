import { computed, ref } from "vue"
import { io } from "socket.io-client"
import uiEvents from "@/configs/uiEvents"
import store from "@/store"
import { GETTERS } from "@/store/constants"
import { parseError } from "@/utils"
import { eventBus } from "./eventBus.plugin"
import auth0 from "./auth0.plugin"

const URL = import.meta.env.VITE_API_URL

/** @type {import("vue").Ref<import("socket.io-client").Socket>} */
const socket = ref()
/** @type {import("vue").ComputedRef<User>} */
const user = computed(() => store.getters[GETTERS.USER])

const prepareSocketListeners = () => {
  socket.value.on("connect", () => {
    window.devLog("Socket connected!")
  })

  socket.value.on("disconnect", (reason) => {
    window.devLog("Socket disconnected:", reason)
    socket.value.removeAllListeners()
  })
}

/**
 * disconnect from the current socket and reconnect to the socket with an access token
 */
const reconnectSocket = async () => {
  if (socket.value) socket.value.disconnect()
  if (!user.value) return

  try {
    const accessToken = await auth0.getAccessTokenSilently()
    socket.value = io(URL, {
      extraHeaders: {
        authorization: `Bearer ${accessToken}`,
      },
    })
    prepareSocketListeners()
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      title: "Unable to verity user",
      body: parseError(err),
      severity: "failure",
      callback: () => (window.location.href = "/auth/login"),
    })
  }
}

/************* the plugin **************/
export default {
  /**
   *  @param {import("vue").App} app
   */
  install(app) {
    eventBus.on(uiEvents.GLOBAL.RECONNECT_SOCKET, reconnectSocket)

    // watch(user, async () => {
    //   await reconnectSocket()
    // })

    app.provide("socket", socket)
    app.provide("reconnectSocket", reconnectSocket)
    app.config.globalProperties.$reconnectSocket = reconnectSocket
  },
}
