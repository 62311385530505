const KEY_MESSAGES = "key-messages"
const TALKING_POINTS = "talking-points"
const POSITIONING_STATEMENT = "positioning-statement"
const BRAND_STORY = "brand-story"
const MARKETING_ANGLE = "marketing-angle"
const VALUE_PROPOSITION = "value-proposition"
const ELEVATOR_PITCH = "elevator-pitch"
const NEWS_HOOK = "news-hook"
const PROFILE_SUMMARY = "profile-summary"
const FREESTYLE_WRITER = "freestyle-writer"
const EDITORIAL_STRATEGY = "editorial-strategy"
const STORY_BRAND_MARKETING = "story-brand"
const PERSONA_BUILDER = "persona-builder"
const BRAND_VOICE = "brand-voice"

export const MESSAGE_TYPES = {
  KEY_MESSAGES,
  TALKING_POINTS,
  POSITIONING_STATEMENT,
  BRAND_STORY,
  MARKETING_ANGLE,
  VALUE_PROPOSITION,
  ELEVATOR_PITCH,
  NEWS_HOOK,
  PROFILE_SUMMARY,
  FREESTYLE_WRITER,
  EDITORIAL_STRATEGY,
  STORY_BRAND_MARKETING,
  PERSONA_BUILDER,
  BRAND_VOICE,
}

export const MESSAGE_TYPE_LABELS = {
  [BRAND_STORY]: "Brand Story",
  [POSITIONING_STATEMENT]: "Brand Positioning",
  [BRAND_VOICE]: "Brand Voice",
  [EDITORIAL_STRATEGY]: "Editorial Strategy",
  [ELEVATOR_PITCH]: "Elevator Pitch",
  [FREESTYLE_WRITER]: "Freestyle (Write Anything)",
  [KEY_MESSAGES]: "Key Messages",
  [MARKETING_ANGLE]: "Marketing Angle",
  [NEWS_HOOK]: "News Hook",
  [PERSONA_BUILDER]: "Persona Builder",
  [PROFILE_SUMMARY]: "Personal or Company Bio",
  [STORY_BRAND_MARKETING]: "Story Brand Framework",
  [TALKING_POINTS]: "Talking Points",
  [VALUE_PROPOSITION]: "Value Proposition",
}

export const MESSAGE_TYPE_PLACEHOLDERS = {
  [TALKING_POINTS]:
    "e.g. Write talking points for a presentation on the future of work. The Objective is to get people to sign up for a free trial of a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [POSITIONING_STATEMENT]:
    "e.g. Write a brand position for a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [BRAND_STORY]:
    "e.g. Write a brand story for a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [FREESTYLE_WRITER]:
    "e.g. Write a communications strategy for the launch of the new Twitter 2.0",
  [MARKETING_ANGLE]:
    "e.g. Write a marketing angle for a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [VALUE_PROPOSITION]:
    "e.g. Write a value proposition for a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [ELEVATOR_PITCH]:
    "e.g. Write an elevator pitch for a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [NEWS_HOOK]:
    "e.g. Write a news hook about a new productivity tool. The target audience is professionals who want to get more done in less time.",
  [PROFILE_SUMMARY]:
    "e.g. Write a personal bio for the CEO of a company that aims to help professionals get more done in less time.",
  [KEY_MESSAGES]:
    "e.g. Create key messages for a company that is launching a new software for its employees.",
  [EDITORIAL_STRATEGY]:
    "e.g. Write an editorial strategy for a new product promoting productivity",
  [STORY_BRAND_MARKETING]:
    "Describe the hero for my brand, Done - it's a productivity tool",
  [PERSONA_BUILDER]:
    "Help me create a persona for our target audience: eco-conscious millennials.",
  [BRAND_VOICE]:
    "Define a voice for a brand that sells handcrafted wooden toys.",
}
