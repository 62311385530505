const LOGLINE = "logline"
const BLURB = "blurb"
const PLOT_SUMMARY = "plot-summary"
const CHARACTER_SKETCH = "character-sketch"
const FREESTYLE = "story-teller"
const CREATIVE_RATIONALE = "creative-rationale"
const SCREEN_PLAY = "screen-play"
const VIGNETTE = "vignette"
const SONGWRITING = "song-writing"
const FIRST_PAGE = "first-page"
const PROMPT_CRAFTER = "prompt-crafter"
const CREATIVE_OUTLINE = "creative-outline"
const PROMPT_TEMPLATE = "prompt-template"
const COMEDY_SKETCH_SCENARIOS = "comedy-sketch-scenarios"

export const ENTERTAINMENT_TYPES = {
  LOGLINE,
  BLURB,
  PLOT_SUMMARY,
  CHARACTER_SKETCH,
  CREATIVE_RATIONALE,
  FREESTYLE,
  SCREEN_PLAY,
  VIGNETTE,
  SONGWRITING,
  FIRST_PAGE,
  PROMPT_CRAFTER,
  CREATIVE_OUTLINE,
  PROMPT_TEMPLATE,
  COMEDY_SKETCH_SCENARIOS,
}

export const ENTERTAINMENT_TYPES_LABELS = {
  [BLURB]: "Blurb",
  [CHARACTER_SKETCH]: "Character Sketch",
  [COMEDY_SKETCH_SCENARIOS]: "Comedy Sketch Scenarios",
  [CREATIVE_OUTLINE]: "Creative Outline",
  [CREATIVE_RATIONALE]: "Creative Rationale",
  [FIRST_PAGE]: "Novel (First Page)",
  [FREESTYLE]: "Storyteller",
  [LOGLINE]: "Logline",
  [PLOT_SUMMARY]: "Plot Summary",
  [PROMPT_CRAFTER]: "Image Prompt",
  [PROMPT_TEMPLATE]: "Prompt Assist",
  [SCREEN_PLAY]: "Screen Play (One-Pager)",
  [SONGWRITING]: "Songwriter",
  [VIGNETTE]: "Vignette",
}
export const ENTERTAINMENT_TYPES_PLACEHOLDERS = {
  [BLURB]:
    " e.g. Write the blurb for a book about how AI is changing the world.",
  [LOGLINE]:
    "e.g. Write a logline for a documentary about how AI is changing the world.",
  [PLOT_SUMMARY]: "e.g. Write a plot summary for a film about AI",
  [CHARACTER_SKETCH]:
    "e.g. Describe the main protaganist for a film about AI and the future",
  [FREESTYLE]: "e.g. Write a story about the how AI is changing the world",
  [CREATIVE_RATIONALE]: "e.g. Write a creative rationale for a film about AI",
  [SCREEN_PLAY]:
    "e.g. Write the opening scene for a play about an AI that becomes sentient",
  [VIGNETTE]: "e.g. Write a vignette about an AI that becomes sentient",
  [SONGWRITING]: "e.g. Write a song about a love lost and found",
  [FIRST_PAGE]:
    "e.g. Write the first page of a novel about an AI that becomes sentient",
  [PROMPT_CRAFTER]:
    "e.g. Imagine what a billboard layout for a Water Ad looks like",
  [CREATIVE_OUTLINE]:
    "e.g. Write a scene outline for a film about an AI that becomes self-aware.",
  [PROMPT_TEMPLATE]:
    "e.g. Write a brand strategy prompt for a new AI multivitamin called 'Exos'.",
  [COMEDY_SKETCH_SCENARIOS]: "e.g. Life After the Pandemic",
}
