const uiEvents = {
  GLOBAL: {
    FILE_UPDATED: "fileUpdated",
    FILE_CREATED: "fileCreated",
    FILE_DELETED: "fileDeleted",
    INSERT_INTO_DOCUMENT: "insertIntoDocument",
    SHOW_ADD_TO_PROJECT_MODAL: "showAddFileToProjectModal",
    HIDE_ADD_TO_PROJECT_MODAL: "hideAddFileToProjectModal",
    SHOW_CREATE_PROJECT_MODAL: "showCreateProjectModal",
    HIDE_CREATE_PROJECT_MODAL: "hideCreateProjectModal",
    SHOW_SHARE_TOOL_MODAL: "showShareToolContentsModal",
    HIDE_SHARE_TOOL_MODAL: "hideShareToolContentsModal",
    SHOW_ALERT_MODAL: "showGlobalErrorModal",
    SHOW_TOAST: "showGlobalToast",
    RECONNECT_SOCKET: "reconnectSocket",
    RESET_AGENT_OBJECTIVE_INPUT: "resetAgentObjectiveInput",
    SHOW_TOKEN_RAN_OUT_MODAL: "showTokenRanOutModal",
  },
}

export default uiEvents
