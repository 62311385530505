import { kebabCase } from "lodash"

const PROPOSAL_INTRO = "proposal-intro"
const PROPOSAL_SUMMARY = "proposal-summary"
const SIMPLIFY = "simplify"
const SPEECH_INTRO = "speech-intro"
const BLOG_INTRO = "blog-intro"
const BLOG_OUTLINE = "blog-outline"
const ESSAY_INTRO = "essay-intro"
const ESSAY_OUTLINE = "essay-outline"
const CONCLUSION = "conclusion"
const FREESTYLE = "freestyle"
const REFRAMER = "reframer"
const BULLET_POINT = "bullet-point"
const REWRITER = "rewriter"
const ANALOGY = "analogy"
const ABSTRACT_WRITER = "abstract-writer"
const COURSE_OUTLINE = "course-outline"
const SPEECH_CONCLUSION = "speech-conclusion"

export const SUMMARY_TYPES = {
  ANALOGY,
  ABSTRACT_WRITER,
  BLOG_INTRO,
  BLOG_OUTLINE,
  BULLET_POINT,
  CONCLUSION,
  COURSE_OUTLINE,
  ESSAY_INTRO,
  ESSAY_OUTLINE,
  FREESTYLE,
  PROPOSAL_INTRO,
  PROPOSAL_SUMMARY,
  SPEECH_CONCLUSION,
  SPEECH_INTRO,
  SIMPLIFY,
  REFRAMER,
  REWRITER,
}

export const SUMMARY_TYPE_LABELS = {
  [ABSTRACT_WRITER]: "Abstract Writer",
  [ANALOGY]: "Analogy",
  [BLOG_OUTLINE]: "Article Outline",
  [BLOG_INTRO]: "Blog Intro",
  [BULLET_POINT]: "Bullet Points",
  [CONCLUSION]: "Conclusion",
  [ESSAY_INTRO]: "Essay Intro",
  [ESSAY_OUTLINE]: "Essay Outline",
  [FREESTYLE]: "Freestyle (Write Anything)",
  [PROPOSAL_INTRO]: "Proposal Intro",
  [PROPOSAL_SUMMARY]: "Proposal Summary",
  [REFRAMER]: "Reframe",
  [REWRITER]: "Rewrite This!",
  [SIMPLIFY]: "Simplify",
  [SPEECH_CONCLUSION]: "Speech Conclusion",
  [SPEECH_INTRO]: "Speech Intro",
}

export const SUMMARY_TYPES_SLUGS = {
  ...SUMMARY_TYPES,
  [FREESTYLE]: kebabCase(SUMMARY_TYPE_LABELS[FREESTYLE]),
  [BLOG_INTRO]: kebabCase(SUMMARY_TYPE_LABELS[BLOG_INTRO]),
  [BULLET_POINT]: kebabCase(SUMMARY_TYPE_LABELS[BULLET_POINT]),
  [CONCLUSION]: kebabCase(SUMMARY_TYPE_LABELS[CONCLUSION]),
  [ESSAY_INTRO]: kebabCase(SUMMARY_TYPE_LABELS[ESSAY_INTRO]),
  [ESSAY_OUTLINE]: kebabCase(SUMMARY_TYPE_LABELS[ESSAY_OUTLINE]),
  [BLOG_OUTLINE]: kebabCase(SUMMARY_TYPE_LABELS[BLOG_OUTLINE]),
  [PROPOSAL_INTRO]: kebabCase(SUMMARY_TYPE_LABELS[PROPOSAL_INTRO]),
  [PROPOSAL_SUMMARY]: kebabCase(SUMMARY_TYPE_LABELS[PROPOSAL_SUMMARY]),
  [REFRAMER]: kebabCase(SUMMARY_TYPE_LABELS[REFRAMER]),
  [REWRITER]: kebabCase(SUMMARY_TYPE_LABELS[REWRITER]),
  [SIMPLIFY]: kebabCase(SUMMARY_TYPE_LABELS[SIMPLIFY]),
  [SPEECH_INTRO]: kebabCase(SUMMARY_TYPE_LABELS[SPEECH_INTRO]),
  [ANALOGY]: kebabCase(SUMMARY_TYPE_LABELS[ANALOGY]),
}
export const SUMMARY_TYPE_PLACEHOLDERS = {
  [BLOG_OUTLINE]:
    "e.g. Write the outline for an article about doing what you love.",
  [BLOG_INTRO]:
    "e.g. An introduction on the history of artificial intelligence.",
  [FREESTYLE]:
    "e.g. Tell the story of a company that invented the first invisible cloak.",
  [CONCLUSION]:
    "e.g. A conclusion for an article about artificial intelligence.",
  [SPEECH_INTRO]:
    "e.g. An intro for a speech on using artificial intelligence to increase productivity.",
  [PROPOSAL_INTRO]:
    "e.g. A proposal intro about using artificial intelligence to improve productivity.",
  [PROPOSAL_SUMMARY]:
    "e.g. A proposal summary outlining the benefits of using artificial intelligence.",
  [SIMPLIFY]: "e.g. Explain artificial intelligence to a 5-year-old",
  [ESSAY_INTRO]:
    "e.g. An essay intro about the future of work in the age of artificial intelligence.",
  [ESSAY_OUTLINE]:
    "e.g. An essay discussing the future of work in the age of artificial intelligence.",
  [REFRAMER]:
    "e.g. Reframe the argument for using artificial intelligence to increase productivity.",
  [BULLET_POINT]: "e.g. Bullet points on the topic of artificial intelligence.",
  [REWRITER]: "Enter the text you want to rewrite",
  [ANALOGY]:
    "e.g. An analogy that explains the benefits of using artificial intelligence.",
  [ABSTRACT_WRITER]:
    "e.g. Write an abstract on research breakthrough in AI, examining the impact on the future of work.",
  [COURSE_OUTLINE]:
    "e.g. Write an outline for a course about artificial intelligence.",
  [SPEECH_CONCLUSION]:
    "Conclude a speech about the importance of community engagement in local governance.",
}
