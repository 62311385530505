const PERFECT_EMAIL = "perfect-email"
const MEMO = "memo"
const LETTER = "letter"
const MEDIA_STATEMENT = "media-statement"
const PRESS_RELEASE = "press-release"
const APOLOGY = "apology"
const CALL_SCRIPT = "call-script"
const QUESTION_ANSWERING = "question-answering"
const EMAIL_SEQUENCE = "email-sequence"
const INFLUENCER_OUTREACH = "influencer-outreach"

export const CORRESPONDENCE_TYPES = {
  QUESTION_ANSWERING,
  PERFECT_EMAIL,
  MEMO,
  LETTER,
  MEDIA_STATEMENT,
  PRESS_RELEASE,
  APOLOGY,
  CALL_SCRIPT,
  EMAIL_SEQUENCE,
  INFLUENCER_OUTREACH,
}

export const CORRESPONDENCE_TYPE_LABELS = {
  [QUESTION_ANSWERING]: "Ask Me Anything",
  [APOLOGY]: "Apology",
  [CALL_SCRIPT]: "Call Script",
  [EMAIL_SEQUENCE]: "Email Sequence",
  [INFLUENCER_OUTREACH]: "Influencer Outreach",
  [LETTER]: "Letter",
  [MEDIA_STATEMENT]: "Media Statement",
  [MEMO]: "Memo",
  [PERFECT_EMAIL]: "Perfect Email",
  [PRESS_RELEASE]: "Press Release",
}

export const CORRESPONDENCE_TYPE_PLACEHOLDERS = {
  [QUESTION_ANSWERING]:
    "Enter a comment, question, topic etc. and generate a response.",
  [PERFECT_EMAIL]:
    "e.g. Write an email to a client to pitch a new campaign idea",
  [MEMO]: "e.g Write a memo announcing the annual company bonus",
  [LETTER]:
    "e.g Write a cover letter applying for a job as Director or Marketing",
  [PRESS_RELEASE]:
    "e.g. Write a  press release for a new productivity tool. The target audience is the professional who wants to get more done in less time.",
  [APOLOGY]:
    "e.g. Write an apology from a company for a data breach. The target audience is customers who have been affected by the breach.",
  [MEDIA_STATEMENT]:
    "e.g. Write a media statement addressing the recall of a product from the shelves",
  [CALL_SCRIPT]:
    "e.g. Write a call script for a new insurance product called 'Life Step' for College Students and Parents",
  [EMAIL_SEQUENCE]:
    "e.g. Write a six email sequence selling a course that helps professionals use AI Writing Tools effectively",
  [INFLUENCER_OUTREACH]:
    "e.g. Write a pitch to a celebrity to promote our new line of natural skincare products.",
}
